

.Container{
display: flex;
justify-content: space-evenly;
background-image: url('C:\Users\HomePC\Desktop\sugar folder\myreactapp\public\mybackgroud2.jpg');
width: 100%;
height: 100%;
}
.Box{
    background: skyblue;
    color: blue;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    padding: 20px;
}
.Box h4{
    background: yellow;
    color: blue;
    padding: 30px;
    margin: 50px;
    border-radius: 5px;
}