nav{
    display: flex;
    width: 100%;
    height: 80px;
    justify-content: center;
    background: blue;

}
.logo{
    color: aqua;
    margin-right: 50%;

}
.head{
align-items: center;
    display: flex;
    justify-content: center;
}
.head a{
    display: flex;
    margin: 30px;


}